<template>
  <v-card
    class="mx-auto"
    width="100%"
  >
    <v-card-title>
      <v-app-bar
        flat
        class="white pa-0"
        elevate-on-scroll
        dense
      >
        <v-toolbar-title
          data-cy="batch_scan_title"
        >
          {{ $t('screen.batch.title') }}
        </v-toolbar-title>
        <v-spacer />
      </v-app-bar>
    </v-card-title>
    <v-card-text>
      <loading
        :active.sync="requestLoading"
        :is-full-page="false"
      />
      <v-container fluid>
        <v-row>
          <v-col cols="6">
            <v-radio-group v-model="batch_type">
              <template v-slot:label>
                <div>{{ $t('screen.batch.type.label') }}</div>
              </template>
              <div
                data-cy="scan_new_wl"
              >
                <v-radio
                  value="new_wl"
                  data-cy="scan_new_wl_btn"
                >
                  <template v-slot:label>
                    <div>{{ $t('screen.batch.type.options.new_wl') }}</div>
                  </template>
                </v-radio>
              </div>
              <div
                data-cy="scan_new"
              >
                <v-radio
                  value="new"
                  data-cy="scan_new_btn"
                >
                  <template v-slot:label>
                    <div>{{ $t('screen.batch.type.options.new') }}</div>
                  </template>
                </v-radio>
              </div>
              <div
                data-cy="scan_all"
              >
                <v-radio
                  value="all"
                  data-cy="scan_all_btn"
                >
                  <template v-slot:label>
                    <div>{{ $t('screen.batch.type.options.all') }}</div>
                  </template>
                </v-radio>
              </div>
            </v-radio-group>
          </v-col>
          <v-col cols="6">
            <v-simple-table v-if="!requestLoading">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th>{{ $t('screen.batch.table.type.label') }}</th>
                    <th>{{ $t('screen.batch.table.cnt.label') }}</th>
                  </tr>
                </thead>
                <tbody>
                  <template>
                    <tr>
                      <td>{{ $t('screen.batch.table.type.options.company') }}</td>
                      <td>{{ batch_info[batch_type].count.company }}</td>
                    </tr>
                    <tr>
                      <td>{{ $t('screen.batch.table.type.options.person') }}</td>
                      <td>{{ batch_info[batch_type].count.person }}</td>
                    </tr>
                  </template>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
          <v-col
            cols="12"
            v-if="!requestLoading"
          >
            <v-btn
              class="mr-2 mb-2"
              @click="batch_type === 'all' ? confirm_start_batch() : start_batch(batch_type)"
              v-if="!batch_details || !batch_scanning"
              :disabled="batch_scanning"
              :loading="batch_status.starting"
              data-cy="start_batch_btn"
            >
              <v-icon left>
                {{ !batch_details.id || batch_details.is_paused ? 'mdi-play' : 'mdi-skip-next' }}
              </v-icon>
              {{ batch_details.id && batch_details.is_paused ? $t('screen.batch.buttons.continue') : $t('screen.batch.buttons.new') }}
            </v-btn>
            <!-- <v-btn
              class="mr-2 mb-2"
              v-else-if="batch_details.id"
              @click="pause_batch"
            >
              <v-icon left>
                mdi-pause
              </v-icon>
              {{ $t('screen.batch.buttons.pause') }}
            </v-btn> -->
            <v-btn
              class="mr-2 mb-2"
              v-if="batch_details.id"
              @click="stop_batch"
              :loading="batch_status.stopping"
              data-cy="stop_batch_btn"
            >
              <v-icon left>
                mdi-stop
              </v-icon>
              {{ batch_scanning ? $t('screen.batch.buttons.stop') : $t('screen.batch.buttons.done') }}
            </v-btn>
            <!-- <v-btn class="mb-2" v-if="batch_details.id" @click="batch_refresh" :disabled="auto_refresh || !batch_scanning" :loading="batch_refreshing">
                            <v-icon left>mdi-refresh</v-icon>
                            {{ $t('screen.batch.buttons.refresh') }}
                        </v-btn> -->
            <!-- <v-checkbox v-if="batch_details.id" dense hide-details v-model="auto_refresh" :label="$t('screen.batch.buttons.auto_refresh')"></v-checkbox> -->
            <v-card
              tile
              flat
              v-if="batch_details.id"
              class="mt-5"
            >
              <v-progress-linear
                :dark="batch_details.progress > 50"
                :color="batch_details.is_failed ? 'error' : ( batch_details.is_paused ? 'warning' : (batch_details.is_completed ? 'success' : 'primary') )"
                :value=" batch_details.status == 'completed' ? 100 : batch_details.progress"
                stream
                class="my-0"
                height="20"
                :buffer-value="0"
              >
                <template v-slot:default="{ value }">
                  <strong
                    data-cy="scan_progress"
                    :indicator=" Math.ceil(value) == 100? 'scan_done': 'scan_processing'"
                  >
                    {{ Math.ceil(value) }}%
                  </strong>
                </template>
              </v-progress-linear>
              <v-card-text>
                {{ $t('screen.batch.id.label') }}: {{ batch_details.id }}<br>
                {{ $t('screen.batch.status.label') }}: {{ batch_details.status }}<br>
                <!-- {{ $t('screen.batch.current_person_id.label') }}: {{ batch_details.current_person_id }}<br>
                                {{ $t('screen.batch.current_company_id.label') }}: {{ batch_details.current_company_id }}<br> -->
                {{ $t('screen.batch.updated_date.label') }}: <timeago
                  :datetime="batch_details.updated_date"
                  :auto-update="60"
                />
              </v-card-text>
            </v-card>
            <v-dialog
              max-width="450px"
              v-model="waitingForConfirm"
            >
              <v-card>
                <v-card-title>{{ $t('dialogs.fullBatchScreeningConfirm.title') }}</v-card-title>
                <v-card-text v-html="$t('dialogs.fullBatchScreeningConfirm.text')" />
                <v-card-actions>
                  <v-spacer />
                  <v-btn
                    text
                    color="error"
                    @click="start_batch(batch_type)"
                  >
                    {{ $t('dialogs.fullBatchScreeningConfirm.actions.yes') }}
                  </v-btn>
                  <v-btn
                    text
                    @click="waitingForConfirm = false"
                  >
                    {{ $t('dialogs.fullBatchScreeningConfirm.actions.no') }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  watch: {
    batch_type (_new_batch_type) {
      this.fetch_info(_new_batch_type)
    }
  },
  computed: {
    ...mapGetters('scan', {
      batch_scanning: 'batch_scanning',
      batch_details: 'batch_details',
      // batch_timer: 'batch_timer',
      batch_refreshing: 'batch_refreshing',
      batch_status: 'batch_status'
    })
    // auto_refresh: {
    //   get () {
    //     return this.batch_timer != null
    //   },
    //   set (auto_refresh) {
    //     if (auto_refresh) { this.batch_keep_refresh() } else { this.batch_stop_refresh() }
    //   }
    // }
  },
  data () {
    return {
      batch_type: 'new_wl',
      requestLoading: false,
      batch_info: {
        new: {
          count: {
            company: 0,
            person: 0
          }
        },
        new_wl: {
          count: {
            company: 0,
            person: 0
          }
        },
        all: {
          count: {
            company: 0,
            person: 0
          }
        }
      },
      waitingForConfirm: false
    }
  },
  methods: {
    ...mapActions('scan', [
      'check_running_batch',
      'start_batch',
      'stop_batch',
      'pause_batch',
      'resume_batch'
      // 'batch_refresh',
      // 'batch_keep_refresh',
      // 'batch_stop_refresh'
    ]),
    fetch_info () {
      this.requestLoading = true
      this.batch_info[this.batch_type].count.company = this.$t('screen.batch.loading') // 'Loading...'
      this.batch_info[this.batch_type].count.person = this.$t('screen.batch.loading') // 'Loading...'

      this.$store.dispatch('scan/batch_info', { batch_type: this.batch_type === 'new_wl' ? 'all' : this.batch_type })
        .then(({ company, person }) => {
          this.batch_info[this.batch_type].count.company = company
          this.batch_info[this.batch_type].count.person = person
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
          this.requestLoading = false
        })
    },
    confirm_start_batch () {
      this.waitingForConfirm = true
    }
  },
  mounted () {
    this.check_running_batch()
    this.fetch_info(this.batch_type)
  }
}
</script>

<style>

</style>
